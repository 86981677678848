import React from 'react'
import styles from "./index.module.css";
import {IoPersonCircle} from 'react-icons/io5';

export default function ProfileHeaderCard({name}) {
    return (
        <div className={styles.profileHeaderCard}>
            <div className={styles.profileHeaderCardIcon}>
                <IoPersonCircle
                    size={100}
                 />
            </div>
            <h2 className={styles.profileHeaderCard_name}>{name}</h2>
        </div>
    )
}
