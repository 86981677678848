import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import styles from "./index.module.css";

import { ProfileHeaderCard, LinkItem } from "../../components";
import AppStoreButton from "../../assets/app-store-button.svg";

export default function SocialsPage() {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_FUNCTION_BASE_URL}/getUserSocials/${id}`)
      .then((res) => res.json())
      .then((profile) => {
        setProfile(profile);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    profile && (
      <div className={styles.container}>
        <ProfileHeaderCard name={profile.name} />
        <div className={styles.socialLinksWrapper}>
          {profile.socialAccounts.map((s, i) => (
            <LinkItem account={s} />
          ))}
        </div>
        <div className={styles.storeButtonsContainer}>
          <a href="https://apps.apple.com/us/app/vida/id1605692386">
            <img
              src={AppStoreButton}
              className={styles.storeButton}
              alt="Get it on App Store"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=codes.vida.nativeapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              className={styles.playButton}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </div>
      </div>
    )
  );
}
