import React from 'react'
import styles from "./index.module.css";
import iconMap from '../../utils/iconmap';
import preprocessSocial from '../../utils/preprocessSocial';

export default function LinkItem({account}) {
    return (
        <a className={styles.container} href={preprocessSocial(account)}>
            {iconMap[account.type]}
        </a>
    )
}
