export default function preprocessSocial(socialdata) {
  switch (socialdata.type) {
    case 'instagram':
      return `https://instagram.com/${socialdata.data}`;
    case 'twitter':
      return `https://twitter.com/${socialdata.data}`;
    case 'tiktok':
      return `https://tiktok.com/${socialdata.data}`;
    case 'snapchat':
      return `https://snapchat.com/add/${socialdata.data}`;
    case 'facebook':
      return `https://www.facebook.com/${socialdata.data}`;
    case 'linkedin':
      return `https://linkedin.com/in/${socialdata.data}`;
    default:
      return socialdata.data;
  }
}