import './App.css';

import {
  useRoutes
} from 'react-router-dom';

import { SocialsPage } from "./pages";

function App() {
  return useRoutes([
    {path: "/", element: <h1>Vida</h1>},
    {path: "/:id", element: <SocialsPage />}
  ])
}

export default App;
