import { 
	IoLogoInstagram,
	IoLogoTwitter,
	IoLogoFacebook,
	IoLogoLinkedin,
	IoLogoTiktok,
	IoLogoSnapchat,
	IoCall
  } from 'react-icons/io5'

const iconMap = {
	instagram: <IoLogoInstagram size={30} />,
	twitter: <IoLogoTwitter size={30} />,
	facebook: <IoLogoFacebook size={30} />,
	linkedin: <IoLogoLinkedin size={30} />,
	tiktok: <IoLogoTiktok size={30} />,
	snapchat: <IoLogoSnapchat size={30} />,
	phone: <IoCall size={30} />
};

export default iconMap;